import "./layout.css"
import "../styles/index.scss"
import React from "react"
import { Button } from 'reactstrap';
import { SocialIcon } from 'react-social-icons';

// export default class NewsLetterSubscription  extends React.Component {
//     render() {
//       return (
  
//         <form style={{ paddingRight: '3px', textAlign: 'center'}} action="https://devashishmulye.substack.com/embed" method="post" target="popupwindow" onsubmit="window.open('https://devashishmulye.substack.com/embed', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true">
//           <p>
//             <label className = "subscribeLabel" htmlFor="tlemail">Subscribe to my NewsLetter</label>
//           </p>
//           <p>
//               <input type="text" style={{width: '140px', paddingRight: "10px"}} name="email" id="tlemail" />
              
//               <input type="hidden" defaultValue={1} name="embed" />
//               <input style = {{backgroundColor: "#555555", marginLeft: "3px", color:"white"}} type="submit" defaultValue="Subscribe" />
            
//                 <a href="https://devashishmulye.substack.com/embed" target="_blank"></a>
//               </p>
//         </form>
//       );
//     }
// }

const socialMediaIcons = [
  {
    url: 'https://github.com/andrewgbliss',
    className: 'fa-github-square',
  },
  {
    url: 'https://gitlab.com/andrewgbliss',
    className: 'fa-gitlab',
  },
  {
    url: 'https://www.npmjs.com/~andrewgbliss',
    className: 'fa-npm',
  },
];

export default class NewsLetterSubscription  extends React.Component {
  
  render() {
    return (
        <div style={{display:"flex", flexWrap: "wrap", flexDirection: "column"}}> 
          <Button outline color="secondary" onClick={() => window.open("https://devashishmulye.substack.com/embed")}>Subscribe to my Newsletter</Button>{' '}
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", marginTop: "0.5rem"}}>
            <SocialIcon url="https://twitter.com/DevashishMulye" fgColor='#c7c7c7' bgColor='#F0F0F0' style={{ height: "3rem", width: "3rem", border:"5px", borderColor: "black" }}/>
            <SocialIcon url="https://www.instagram.com/devashish.mulye/?hl=en" fgColor='#c7c7c7' bgColor='#F0F0F0' style={{ height: "3rem", width: "3rem" }}/>
            <SocialIcon url="https://www.youtube.com/channel/UC0qzknXN2zqRf_C2RiRBKpg" fgColor='#c7c7c7' bgColor='#F0F0F0' style={{ height: "3rem", width: "3rem" }}/>
          </div>
        </div>


    );
  }
}

